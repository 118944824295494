import { dcFactory } from '@deleteagency/dc';

class App {
    constructor() {
        this.config = window.appConfig || {};
        this.state = window.appState || {};
        this.isReduceMotionEnabled = matchMedia(
            'only screen and (prefers-reduced-motion: reduce)'
        ).matches;
        this.isEditingMode = document.body.classList.contains('is-editing-mode');
        this.isMobile = document.documentElement.classList.contains('is-mobile');
    }

    init() {
        dcFactory.init();
    }
}

const instance = new App();
export default instance;
